<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
        </div>
        <div class="card-body mb-47 mt-2 white">
          <p class="t1 mb-3 text-center">
            <strong
              >เอกสารแจ้งการประมวลผลข้อมูลส่วนบุคคล (Privacy Notice) สำหรับสมาชิก “{{
                app_name
              }}”</strong
            >
          </p>
          <p class="t2" style="text-indent: 40px">
            <strong>“{{ app_name }}”</strong>
            มีการดำเนินการจัดเก็บข้อมูลส่วนบุคคลสำหรับขอรับบริการต่าง ๆ
            ภายใต้เงื่อนไขการให้บริการของ{{ app_name }} รวมไปถึงกิจกรรมต่าง ๆ ของ{{
              app_name
            }}
            ดังนั้น
            {{ app_name }}
            จึงขอแจ้งให้สมาชิกทุกท่านทราบเกี่ยวกับวิธีการและวัตถุประสงค์ในการเก็บรวบรวม
            ใช้ และเปิดเผยแพร่ข้อมูลส่วนบุคคล
            โดยสิทธิในข้อมูลส่วนบุคคลของสมาชิกทุกท่านจะอยู่ในความครอบครองของ{{
              app_name
            }}
            และ {{ app_name }} จะประมวลผลข้อมูลส่วนบุคคลด้วยความโปร่งใสชัดเจน
            สอดคล้องกับพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 ทั้งนี้
            {{ app_name }}
            จึงขอให้สมาชิกโปรดอ่านเพื่อทำความเข้าใจถึงการประมวลผลและการคุ้มครองข้อมูลส่วนบุคคลของ
            {{ app_name }} หากมีข้อสงสัยประการใดสามารถติดต่อสอบถาม{{ app_name }}
            ได้ตามรายละเอียดการติดต่อที่ปรากฏตามเอกสารฉบับนี้
          </p>
          <ol class="t2 pl-5 sub-items mb-10" style="line-height: 180%">
            <li class="level-1">
              <strong><u>เอกสารแจ้งการประมวลผลข้อมูลส่วนบุคคล</u></strong>
              <ol class="sub-items indent">
                <li>
                  <strong>วัตถุประสงค์และการประมวลผลข้อมูล </strong>
                  <p style="text-indent: 40px">
                    การเก็บข้อมูลส่วนบุคคล:
                    สมาชิกจำเป็นต้องให้ข้อมูลส่วนบุคคลเพื่อระบุและใช้ในการยืนยันตัวตนสมาชิก
                    เพื่อให้สมาชิกได้รับสิทธิในการรับบริการ และสิทธิประโยชน์จาก{{
                      app_name
                    }}
                    ตามเงื่อนไขและบริการ ทั้งนี้ ข้อมูลของสมาชิกจะมีส่วนช่วยให้{{
                      app_name
                    }}
                    ใช้ในการวางและกำหนดนโยบายสำหรับมาตรการการให้บริการในอนาคตต่อไป
                  </p>
                  <p style="text-indent: 40px">
                    การประมวลผลข้อมูล:
                    {{ app_name }}
                    ดำเนินการประมวลผลข้อมูลส่วนบุคคลของสมาชิกภายใต้การประมวลผล ดังต่อไปนี้
                    (1) สัญญา (Contract) เมื่อสมาชิกสมัครเป็นสมาชิกเพื่อใช้บริการ
                    ภายใต้เงื่อนไขการให้บริการของ{{
                      app_name
                    }}
                    สมาชิกจำเป็นต้องให้ข้อมูลส่วนบุคคล
                    เพื่อนำข้อมูลส่วนบุคคลไปประมวลผลที่เกี่ยวกับการให้บริการตามเงื่อนไขและข้อกำหนดของ{{
                      app_name
                    }}
                    รวมทั้งใช้ในการติดต่อสื่อสาร ตอบขอซักถามของสมาชิก ทั้งนี้
                    หากสมาชิกไม่ให้ข้อมูลส่วนบุคคลดังกล่าว
                    จะส่งผลกระทบทำให้ไม่สามารถขอรับบริการเงื่อนไขการให้บริการของ{{
                      app_name
                    }}
                    ได้ และ{{ app_name }} ไม่สามารถจัดสิทธิหรือประโยชน์ตามเงื่อนไข
                    ไม่สามารถติดต่อสื่อสาร ไม่สามารถตรวจสอบความสามารถในการเข้าทำสัญญา
                    รวมถึงการตรวจสอบความเป็นตัวตนของสมาชิกได้ อันเป็นไปตามมาตรา 24 (3)
                    ของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 (2) ความยินยอม
                    (Consent)
                    {{ app_name }}
                    จะขอความยินยอมจากสมาชิกก่อนนำข้อมูลส่วนบุคคลของสมาชิกไปในใช้ในการประมวลผล
                    เพื่อแจ้งหรือนำเสนอบริการ สิทธิ
                    หรือประโยชน์ให้กับสมาชิกเฉพาะบุคคลกับสมาชิก
                    หรือเพื่อเปิดเผยข้อมูลส่วนบุคคลของสมาชิกให้กับหน่วยงานร่วมดำเนินการอื่น
                    ๆ ของ{{
                      app_name
                    }}
                    เพื่อการยืนยันตัวตนและเชื่อมบัญชีสมาชิกภายใต้ข้อกำหนดการบริการของ{{
                      app_name
                    }}
                    ในการนี้
                    สมาชิกมีความประสงค์จะเปลี่ยนแปลงความยินยอมสามารถเปลี่ยนแปลงได้ด้วยตัวสมาชิกเองในระบบสมาชิก
                    หรือติดต่อ{{ app_name }}
                    และแจ้งความประสงค์ได้ตามรายละเอียดเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                    (ข้อ 5) (3) อื่นๆ
                    {{ app_name }}
                    อาจมีการนำข้อมูลส่วนบุคคลของสมาชิกไปใช้ในการประมวลผลเพื่อการจัดการและการจัดทำรายงานของ{{
                      app_name
                    }}
                    สำหรับใช้ในการวางและกำหนดนโยบายสำหรับการให้บริการอื่น ๆ ในอนาคตของ{{
                      app_name
                    }}
                    หรือปฏิบัติภารกิจอื่น ๆ โดยชอบด้วยกฎหมายได้ ซึ่งเป็นไปตามมาตรา 24
                    แห่งพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
                  </p>
                </li>
                <li>
                  <strong
                    >ข้อมูลส่วนบุคคลที่จะทำการประมวลผลและระยะเวลาในการเก็บรวบรวมข้อมูล
                  </strong>
                  <p style="text-indent: 40px">
                    ประเภทหรือรายการข้อมูลส่วนบุคคล -
                    ข้อมูลที่ปรากฏตามหน้าบัตรประจำตัวประชาชน - ข้อมูลพาสปอร์ต (Passport) -
                    ข้อมูลรายการขอรับบริการ ภายใต้ข้อกำหนดการบริการของ{{ app_name }} -
                    ข้อมูลที่ได้รับจากการสอบถาม การให้ข้อคิดเห็น คำติชม คำถามของสมาชิก
                    ระยะเวลาในการเก็บรวบรวมข้อมูล 90 วัน นับถัดจากวันสิ้นสุดความเป็นสมาชิก
                    ทั้งนี้ เมื่อพ้นกำหนดระยะเวลาข้างต้น {{ app_name }}จะทำการลบ
                    หรือทำให้ข้อมูลส่วนบุคคลไม่สามารถระบุตัวบุคคลของสมาชิกได้
                    เว้นแต่มีเหตุจำเป็นที่ได้บัญญัติไว้เป็นสาระสำคัญตามกฎหมายอื่น
                  </p>
                </li>
                <li>
                  <strong
                    >ประเภทของบุคคลหรือหน่วยงานซึ่งข้อมูลส่วนบุคคลอาจเปิดเผย
                  </strong>
                  <p style="text-indent: 40px">
                    {{ app_name }} อาจทำการเปิดเผยข้อมูลส่วนบุคคลของสมาชิก
                    ให้กับหน่วยร่วมดำเนินงานของ{{ app_name }} เช่น
                    หน่วยงานตรวจสอบภายนอกจากหน่วยงานภาครัฐ
                    หน่วยงานราชการตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
                    กำหนด เป็นต้น
                  </p>
                </li>
                <li>
                  <strong>สิทธิของเจ้าของข้อมูล </strong>
                  <p style="text-indent: 40px">
                    (1) สิทธิในการเพิกถอนความยินยอมให้{{ app_name }}
                    ประมวลผลข้อมูลส่วนบุคคลของสมาชิก
                  </p>
                  <p style="text-indent: 40px">
                    (2) สิทธิในการเข้าถึงและรับสำเนาข้อมูลส่วนบุคคลที่{{ app_name }}
                    ได้เก็บรวบรวม ใช้ เปิดเผย
                  </p>
                  <p style="text-indent: 40px">
                    (3) สิทธิในการขอให้โอนย้ายข้อมูลส่วนบุคคลของสมาชิก
                  </p>
                  <p style="text-indent: 40px">
                    (4) สิทธิในการคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผย
                    ข้อมูลส่วนบุคคลของสมาชิก
                  </p>
                  <p style="text-indent: 40px">
                    (5) สิทธิในการขอให้ลบ ทำลาย
                    หรือทำให้ข้อมูลส่วนบุคคลของสมาชิกเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคลนั้นได้
                  </p>
                  <p style="text-indent: 40px">
                    (6) สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลของสมาชิกให้ถูกต้องเป็นปัจจุบัน
                    สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
                  </p>
                  <p style="text-indent: 40px">
                    (7) สิทธิในการที่จะร้องเรียนในกรณีที่สมาชิกเห็นว่าถูก{{
                      app_name
                    }}
                    หรือเจ้าหน้าที่ หรือหน่วยร่วมดำเนินงานของ{{
                      app_name
                    }}
                    ละเมิดสิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562
                    การร้องขอใด ๆ
                    เพื่อการให้สิทธิของสมาชิกตามที่กล่าวข้างต้นจะต้องกระทำเป็นลายลักษณ์อักษร
                    และ{{
                      app_name
                    }}
                    จะดำเนินการภายในระยะเวลาตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                    พ.ศ.2562 กำหนดที่เกี่ยวกับสิทธิของสมาชิกในฐานะเจ้าของข้อมูลส่วนบุคคล
                    ข้อจำกัดในการให้บริการกับสมาชิกในกรณีที่สมาชิกขอให้{{ app_name }} ลบ
                    ทำลายข้อมูล ระงับ ขอให้โอน การคัดค้าน
                    หรือทำให้ข้อมูลไม่สามารถระบุตัวตนได้ หรือถอนความยินยอม
                    อาจจะทำให้เกิดข้อจำกัดกับการดำเนินในการให้บริการของ{{ app_name }}
                    กับสมาชิกบางกรณีได้ ทั้งนี้
                    ภายใต้ข้อกำหนดเงื่อนไขของการเป็นสมาชิกและตามที่พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                    พ.ศ.2562 กำหนด
                  </p>
                </li>
                <li>
                  <strong>รายละเอียดเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล </strong>
                  <p style="text-indent: 40px">
                    หากมีเหตุร้องเรียนเกี่ยวกับข้อมูลส่วนบุคคลสามารถติดต่อประสานงานมายังเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลได้ในช่องทางดังนี้
                  </p>
                  <p style="text-indent: 40px">เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</p>
                  <p style="text-indent: 40px">ที่อยู่: {{ address }}</p>
                  <p style="text-indent: 40px">โทรศัพท์: {{ tel }}</p>
                  <p style="text-indent: 40px">e-mail: {{ email }}</p>
                  <p style="text-indent: 40px">
                    หากสมาชิกเห็นว่าการประมวลผลข้อมูลส่วนบุคคลของสมาชิกไม่เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
                    พ.ศ.2562
                    สมาชิกมีสิทธิที่จะร้องเรียนไปยังสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลได้ที่
                  </p>
                  <p style="text-indent: 40px">
                    สำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
                  </p>
                  <p style="text-indent: 40px">
                    ที่อยู่: 120 หมู่ 3 ศูนย์ราชการเฉลิมพระเกียรติ 80 พรรษาฯ
                    อาคารรัฐประศาสนภักดี (อาคารบี) ชั้น 7 ถนนแจ้งวัฒนะ แขวงทุ่งสองห้อง
                    เขตหลักสี่ กรุงเทพฯ 10210
                  </p>
                  <p style="text-indent: 40px">โทรศัพท์: 02 142 1033, 02 141 6993</p>
                  <p style="text-indent: 40px">e-mail: saraban@pdpc.or.th</p>
                </li>
              </ol>
            </li>
          </ol>
          <p class="t1 mb-3 text-center">
            <strong>คำยินยอมเพื่อเปิดเผยข้อมูล</strong>
          </p>
          <ol class="t2 pl-5 sub-items mb-10" style="line-height: 180%">
            <li class="level-1" style="text-indent: 40px">
              {{ app_name }} จะขอดำเนินการจัดเก็บข้อมูลเกี่ยวกับการขอรับบริการ
              ภายใต้เงื่อนไขการให้บริการของ{{ app_name }} รวมไปถึงกิจกรรมอื่น ๆ ของ{{
                app_name
              }}
              พร้อมทั้งการส่งข้อความแจ้งเตือนเพื่อให้ท่านได้รับข้อมูลข่าวสาร นัดหมาย
              และติดตามการดำเนินงานต่าง ๆ ของ{{ app_name }}
              โดยท่านจะได้รับความคุ้มครองตาม พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
            </li>
            <li class="level-1" style="text-indent: 40px">
              ข้าพเจ้าขอให้ {{ app_name }} ดำเนินการเกี่ยวกับการขอรับบริการ
              ภายใต้เงื่อนไขการให้บริการของ{{ app_name }} รวมไปถึงกิจกรรมอื่น ๆ ของ{{
                app_name
              }}
              ให้แก่ข้าพเจ้าจนกว่าจะมีการยกเลิกการขอรับบริการ รวมไปถึงการกิจกรรมต่างๆ
              ของ{{ app_name }}
            </li>
            <li class="level-1" style="text-indent: 40px">
              ข้าพเจ้ายอมรับ
              และตกลงว่าบรรดาข้อมูลส่วนบุคคลและข้อมูลทั้งหมดที่ระบุไว้ในคำขอของระบบนี้เป็นข้อมูลที่ถูกต้องตามความเป็นจริง
              {{ app_name }}
              ขอสงวนสิทธิ์ในการให้สิทธิประโยชน์หรือยกเลิกสิทธิประโยชน์กรณีที่ตรวจสอบพบว่าข้อมูลดังกล่าวไม่ตรงกับความเป็นจริง
            </li>
            <li class="level-1" style="text-indent: 40px">
              ข้าพเจ้ายอมรับ
              และตกลงว่าบรรดาข้อมูลส่วนบุคคลและข้อมูลทั้งหมดที่ระบุไว้ในคำขอของระบบนี้หรือที่เกิดจากการทำธุรกรรมของข้าพเจ้านั้นเป็นทรัพย์สินของ{{
                app_name
              }}
            </li>
            <li class="level-1" style="text-indent: 40px">
              วัตถุประสงค์ในการเก็บข้อมูลส่วนบุคคลและข้อมูลอื่นๆ ดังกล่าวข้างต้น
              เพื่อใช้ข้อมูลดังกล่าวให้สอดคล้องกับวัตถุประสงค์ในการขอรับขอรับบริการ
              ภายใต้เงื่อนไขการให้บริการของ{{ app_name }} รวมไปถึงกิจกรรมอื่น ๆ ของ{{
                app_name
              }}
              โดยการใช้ข้อมูลส่วนบุคคลและข้อมูลดังกล่าว จะอยู่ในขอบข่ายและหลักเกณฑ์ที่{{
                app_name
              }}
              กำหนด
            </li>
            <li class="level-1" style="text-indent: 40px">
              ข้าพเจ้ายินยอมและมอบหมายให้{{ app_name }} ใช้
              หรือแลกเปลี่ยนข้อมูลส่วนบุคคลของข้าพเจ้าซึ่งได้ให้ไว้ในคำขอของระบบนี้
              หรือได้รับจากการทำธุรกรรมกับ{{ app_name }}
              ให้แก่บุคคลอื่นในลักษณะที่เห็นว่าเป็นประโยชน์กับข้าพเจ้า
            </li>
            <li class="level-1" style="text-indent: 40px">
              ข้าพเจ้ายอมรับสิทธิประโยชน์ที่ทาง{{ app_name }} เสนอให้แก่ข้าพเจ้า
              โดยขึ้นอยู่กับนโยบายและเงื่อนไขของ{{ app_name }}
              ที่ข้าพเจ้าได้ยื่นคำขอ
              รวมทั้งข้อกำหนดและเงื่อนไขที่แก้ไขเพิ่มเติมในภายหน้าโดยมิต้องแจ้งให้กับข้าพเจ้าทราบล่วงหน้า
            </li>
            <li class="level-1" style="text-indent: 40px">
              ข้าพเจ้าได้ศึกษาและยอมรับเงื่อนไขการขอรับบริการ
              ภายใต้เงื่อนไขการให้บริการของ{{ app_name }} รวมไปถึงกิจกรรมอื่น ๆ ของ{{
                app_name
              }}
              ตามที่ได้ระบุไว้ตามประกาศ
            </li>
            <li class="level-1" style="text-indent: 40px">
              ข้าพเจ้าทราบและตกลงว่า {{ app_name }} มีสิทธิ์ปฏิเสธการขอรับบริการ
              ภายใต้เงื่อนไขการให้บริการของ{{ app_name }} รวมไปถึงกิจกรรมอื่น ๆ ของ{{
                app_name
              }}
              โดยไม่จำเป็นต้องระบุเหตุแห่งการปฏิเสธ
            </li>
          </ol>
          <p><strong>ท่านได้อ่าน และยินยอมเพื่อเปิดเผยข้อมูล</strong></p>
        </div>
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="next" class="button">ยินยอม</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
export default defineComponent({
  name: "Register",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const app_name = ref(process.env.VUE_APP_NAME);
    const address = ref(process.env.VUE_APP_ADDRESS);
    const tel = ref(process.env.VUE_APP_TEL);
    const email = ref(process.env.VUE_APP_EMAIL);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const next = () => {
      let form = {
        id_line: store.getters.getAccessToken.accesstoken,
        agreement: true,
      };
      store.dispatch(SET_REGISTER, form);
      router.push({ name: "Register_1" });
    };

    return {
      address,
      app_name,
      tel,
      email,
      next,
    };
  },
});
</script>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
